import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import {
  PROJECT_DETAILS,
  REFRESH_TOKEN,
  TENANT_ID,
  TOKEN,
  USER_KEY
} from './common/constants';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage.getItem(USER_KEY);
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage.getItem(TOKEN),
  // eslint-disable-next-line no-undef
  authRefreshToken: localStorage.getItem(REFRESH_TOKEN),
  showPrompt: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER_KEY,
        user && Object.keys(user).length ? JSON.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action.data };
    case 'SET_SHOW_PROMPT':
      return { ...state, showPrompt: action?.data };
    case 'LOGOUT':
      delete api.defaults.headers.common.Authorization;
      // eslint-disable-next-line no-undef
      localStorage.clear();
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        authRefreshToken: null,
        user: {}
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action.data };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    case 'SET_PROJECT_DETAILS':
      // eslint-disable-next-line no-undef
      localStorage.setItem(PROJECT_DETAILS, action.data);
      return { ...state, projectDetails: action.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(REFRESH_TOKEN, action.data);
      return { ...state, authRefreshToken: action.data };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action.data };
    case 'SET_PROJECT_ROLE':
      return { ...state, projectRole: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(TOKEN) || null;
  };
  const getCurrentTenant = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(TENANT_ID) || null;
  };

  const changeTenant = (tenantId) => {
    // eslint-disable-next-line no-undef
    localStorage.setItem(TENANT_ID, tenantId);
  };
  const getTenantData = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.tenants || '';
  };
  const getTenantUser = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.tenantUser || '';
  };
  const getRefreshToken = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(REFRESH_TOKEN) || null;
  };

  const getCurrentUser = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(USER_KEY)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage.getItem(USER_KEY))
      : {};
  };
  const getCurrentProjectDetail = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(PROJECT_DETAILS)
      ? // eslint-disable-next-line no-undef
        JSON.parse(localStorage.getItem(PROJECT_DETAILS))
      : {};
  };

  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return (loggedInUser && loggedInUser.roles && loggedInUser.roles[0]) || '';
  };

  const isAuthenticated = () => {
    return state.authenticated;
  };

  const initializeAuth = (authToken, authRefreshToken, userData) => {
    const token = authToken || getToken();
    const refreshToken = authRefreshToken || getRefreshToken();
    const user = userData || getCurrentUser();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refreshToken });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      if (user && user.roles && user.roles[0]) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: user.roles[0] });
      }
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser,
    getRefreshToken,
    getCurrentTenant,
    getTenantData,
    getTenantUser,
    changeTenant,
    getCurrentProjectDetail
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;
export { AppContext, AppContextConsumer, AppContextProvider };
